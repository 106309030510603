'use client';
import { checkImageValidity } from '@/lib/utils';
import Image, { StaticImageData } from 'next/image';
import React, { useEffect, useState } from 'react';

interface ValidatedImageProps {
  src: StaticImageData | string;
  alt: string;
  width?: number;
  height?: number;
  fill?: boolean;
  layout?: string;
  defaultUrl?: string;
  className?: string;
  priority?: boolean;
  bypassValidation?: boolean;
}

const Img: React.FC<ValidatedImageProps> = ({
  src,
  alt,
  width = 400,
  height = 400,
  fill,
  layout,
  defaultUrl,
  className,
  priority,
  bypassValidation,
}) => {
  const [isValidImage, setIsValidImage] = useState(false);

  const defaultSrc = defaultUrl ? defaultUrl : '/assets/placeholder.svg';

  useEffect(() => {
    // Function to validate if the URL points to an image
    const validateImageUrl = async (url: string) => {
      try {
        const res = await fetch(url);
        const contentType = res.headers.get('content-type');
        if (res.ok && contentType && contentType.startsWith('image/')) {
          setIsValidImage(true);
        } else {
          setIsValidImage(false);
        }
      } catch (error) {
        setIsValidImage(false);
      }
    };

    validateImageUrl(typeof src === 'string' ? src : src.src);
  }, [src]);

  if (fill)
    return (
      <Image
        src={bypassValidation ? src : isValidImage ? src : defaultSrc}
        alt={alt}
        fill
        className={className}
        crossOrigin="anonymous"
        placeholder="blur"
        blurDataURL={defaultSrc}
        priority={priority}
      />
    );
  if (layout === 'fill')
    return (
      <Image
        src={bypassValidation ? src : isValidImage ? src : defaultSrc}
        alt={alt}
        layout={layout}
        className={className}
        crossOrigin="anonymous"
        placeholder="blur"
        blurDataURL={defaultSrc}
        priority={priority}
      />
    );

  return (
    <Image
      src={bypassValidation ? src : isValidImage ? src : defaultSrc}
      alt={alt}
      height={height}
      width={width}
      className={className}
      crossOrigin="anonymous"
      blurDataURL={defaultSrc}
      priority={priority}
    />
  );
};

export default Img;
