import React, { useEffect, useRef } from 'react';

interface MentionItemProps extends React.ComponentPropsWithoutRef<'div'> {
  isActive: boolean;
}

export const MentionItem = ({ isActive, className, style, ...props }: MentionItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive) {
      ref.current?.scrollIntoView({ block: 'nearest' });
    }
  }, [isActive]);

  return (
    <div
      ref={ref}
      className={
        'mentionsItem text-xs lg:text-sm px-2 py-1 rounded' + (className ? ` ${className}` : '')
      }
      style={{
        backgroundColor: isActive ? '#FAF4FF' : undefined,
        color: isActive ? '#601E8A' : undefined,
        ...style,
      }}
      {...props}
    />
  );
};
