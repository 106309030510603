'use client';

import { SuggestionKeyDownProps, SuggestionProps } from '@tiptap/suggestion';
import Avatar from '@/components/atom/avatar';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import { MentionItem } from './MentionItem';
import { UserProfile } from '@/types';
import useGetFollowingQuery from '@/hooks/react-query/connections/queries/useGetFollowingQuery';
import useGetFollowersQuery from '@/hooks/react-query/connections/queries/useGetFollowersQuery';
import { useSession } from 'next-auth/react';
import InfiniteScrollContainer from '@/components/InfiniteScrollContainer';
import useOnClickOutside from '@/hooks/use-click-outside';

interface MentionListProps extends SuggestionProps {}

interface MentionListActions {
  onKeyDown: (props: SuggestionKeyDownProps) => void;
}

export const MentionList = forwardRef<MentionListActions, MentionListProps>(
  ({ clientRect, command, query }, ref) => {
    const session = useSession();

    console.log({ query });

    const followingQuery = useGetFollowingQuery({
      SearchParam: query,
      UserId: session.data?.user?.userId,
    });
    const followersQuery = useGetFollowersQuery({
      SearchParam: query,
      UserId: session.data?.user?.userId,
    });
    const referenceEl = useMemo(
      () => (clientRect ? { getBoundingClientRect: clientRect } : null),
      [clientRect]
    );

    const users: UserProfile[] = useMemo(
      () =>
        [...(followersQuery.data || []), ...(followingQuery.data || [])].reduce((acc, item) => {
          if (!acc.some((existing: UserProfile) => existing.username === item.username)) {
            acc.push(item);
          }
          return acc;
        }, []),
      [JSON.stringify(followingQuery.data), JSON.stringify(followersQuery.data)]
    );

    const handleCommand = (index: number) => {
      const selectedPerson = users[index];
      command({ id: selectedPerson.username, label: selectedPerson.username });
    };

    const [hoverIndex, setHoverIndex] = useState(0);
    useImperativeHandle(ref, () => ({
      onKeyDown: ({ event }) => {
        const { key } = event;

        if (key === 'ArrowUp') {
          setHoverIndex((prev) => {
            const beforeIndex = prev - 1;
            return beforeIndex >= 0 ? beforeIndex : 0;
          });
          return true;
        }

        if (key === 'ArrowDown') {
          setHoverIndex((prev) => {
            const afterIndex = prev + 1;
            const peopleCount = users.length - 1;
            return afterIndex < peopleCount ? afterIndex : peopleCount;
          });
          return true;
        }

        if (key === 'Enter') {
          handleCommand(hoverIndex);
          return true;
        }

        return false;
      },
    }));

    const [el, setEl] = useState<HTMLDivElement | null>(null);
    const popperRef = useRef<HTMLDivElement | null>(null);
    const [isOpen, setIsOpen] = useState(true);
    const { styles, attributes } = usePopper(referenceEl as any, el, {
      placement: 'bottom-start',
    });

    useOnClickOutside(popperRef, () => setIsOpen(false));

    if (users.length === 0 || !isOpen) return null;

    return createPortal(
      <div
        ref={(node) => {
          setEl(node);
          popperRef.current = node;
        }}
        className="bg-white shadow-md z-[9999] mentionsContainer rounded"
        style={styles.popper}
        {...attributes.popper}
      >
        <InfiniteScrollContainer
          className="h-[224px] overflow-y-auto space-y-2.5 f-scroll p-2"
          loading={followersQuery.isFetchingNextPage || followingQuery.isFetchingNextPage}
          onBottomReached={() => {
            followersQuery.hasNextPage &&
              !followersQuery.isFetching &&
              followersQuery.fetchNextPage();

            followingQuery.hasNextPage &&
              !followingQuery.isFetching &&
              followingQuery.fetchNextPage();
          }}
        >
          {users.map((person, index) => (
            <MentionItem
              key={person.username}
              isActive={index === hoverIndex}
              onMouseEnter={() => setHoverIndex(index)}
              onClick={() => handleCommand(index)}
              className="flex items-center gap-2"
            >
              <Avatar
                fallbackText={`${person.fullname}`}
                className="h-10 w-10"
                src={person?.avatarUrl ?? ''}
              />
              <div className="flex flex-col">
                <span className="text-sm">{person.fullname}</span>
                <span className="text-[10px] text-gray-brand3">{person.username}</span>
              </div>
            </MentionItem>
          ))}
        </InfiniteScrollContainer>
      </div>,
      document.body
    );
  }
);
