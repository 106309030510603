import { axiosClientInstance } from '@/configs/axiosClientInstance';
import { FUSION_API } from '@/configs/global-variables';
import { COMMUNITIES_ENDPOINTS } from '@/constants/endpoints/community';
import { useInfiniteQuery } from '@tanstack/react-query';

export type IFollowingParams = {
  UserId?: number;
  SearchParam: string;
  PageSize?: number;
  PageNumber?: number;
};

export async function getFollowers(params: IFollowingParams) {
  try {
    const res = await axiosClientInstance.get(COMMUNITIES_ENDPOINTS.getFollowers, {
      params: {
        ...params,
      },
      baseURL: FUSION_API.communitiesApi,
    });
    return res.data?.data;
  } catch (error: unknown) {
    return Promise.reject(error);
  }
}

const useGetFollowersQuery = ({ PageSize = 15, PageNumber, ...rest }: IFollowingParams) => {
  const { data, ...other } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: ['GetUserFollowers', { PageSize, ...rest }],
    queryFn: ({ pageParam }) => getFollowers({ PageSize, PageNumber: pageParam, ...rest }),
    getNextPageParam: (lastPage: any, pages) =>
      lastPage?.hasNextPage ? pages.length + 1 : undefined,
    enabled: !!rest.UserId,
  });

  const payload = data?.pages.reduce((acc, page) => [...acc, ...(page?.data ?? [])], []);

  return { ...other, data: payload, total: data?.pages[0]?.totalCount ?? '0' };
};

export default useGetFollowersQuery;
