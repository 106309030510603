const SendPost = ({ filled }: { filled?: boolean }) =>
  filled ? (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.1372 2.96004L7.10719 5.96004C1.03719 7.99004 1.03719 11.3 7.10719 13.32L9.78719 14.21L10.6772 16.89C12.6972 22.96 16.0172 22.96 18.0372 16.89L21.0472 7.87004C22.3872 3.82004 20.1872 1.61004 16.1372 2.96004ZM16.4572 8.34004L12.6572 12.16C12.5072 12.31 12.3172 12.38 12.1272 12.38C11.9372 12.38 11.7472 12.31 11.5972 12.16C11.3072 11.87 11.3072 11.39 11.5972 11.1L15.3972 7.28004C15.6872 6.99004 16.1672 6.99004 16.4572 7.28004C16.7472 7.57004 16.7472 8.05004 16.4572 8.34004Z"
        fill="#601E8A"
      />
    </svg>
  ) : (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.16641 5.2668L13.2414 2.90846C16.4164 1.85013 18.1414 3.58346 17.0914 6.75846L14.7331 13.8335C13.1497 18.5918 10.5497 18.5918 8.96641 13.8335L8.26641 11.7335L6.16641 11.0335C1.40807 9.45013 1.40807 6.85846 6.16641 5.2668Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42578 11.375L11.4091 8.3833"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

export default SendPost;
